import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout/index";
import MetaTags from "../components/MetaTags/index";
import styles from "./tagPage.module.css";
import { getPostsFromGraphQL } from "../utils";
import { ChartsList } from "../components";
import _ from "lodash";

const INFO_TYPE_TAG = 'information type';

function TagPage(props) {
  const { tagsFmtKey, tagName, tag } = props.pageContext;
  const {data} = props;
  const tagsFmtKeyYaml = `${tagsFmtKey}Yaml`;
  const theTag = data[tagsFmtKeyYaml];
  const posts = getPostsFromGraphQL(data, true, undefined, tagsFmtKey); // now all charts with variations are showing, maybe add more/less switch to each function
  const description = theTag ? theTag.description : "";
  const capitalizeFL = text => text.charAt(0).toUpperCase() + text.slice(1); //capitalize first letter
  const tagIconFname = `/tags/${_.kebabCase(tagName)}s/${_.kebabCase(tag)}.svg`;
  return (
    <Layout type="full">
      <MetaTags
        title={` ${capitalizeFL(tag)} function - Graphopedia`}
        description={description}
      />
      <div className={styles.function}>
        <div className={styles.headline}>
          <div>
            <div className={styles.breadcrumb}> 
              <Link to={"/" + _.kebabCase(tagName)} title={`view all ${tagName}`}>{tagName}</Link> 
            </div>
            <h1>
              <img className={styles.tagIcon} src={tagIconFname} /> {_.capitalize(tag)}
            </h1>
          </div>
          { (tagName === INFO_TYPE_TAG)
            ? (<div>
                <p className={styles.description}>{theTag.questionAnswered}</p>
                <p id="extra-description"> {description} </p>
               </div>
            )
            : (
              <p className={styles.description}>
                {description}
              </p>
            )
          }
        </div>

        <div id="function_charts">
          <p className={styles.divider}>Charts Included</p>
          <ChartsList isExpanded={true} posts={posts} />
        </div>
      </div>
    </Layout>
  );
}


export default TagPage;

// TODO: reuse fragments of GraphQL
// the query fragments allMarkdownRemark and xxxYaml are repeated for every tag, couldn't find how to reuse it,
// because GraphQl doesn't support dynamic fields through variables
export const query = graphql`
query TagsQuery($tag: String!, $incFunction: Boolean!, $incVisPrinciple: Boolean!, $incAxis: Boolean!, $incInfo: Boolean!, $tagId: String) {
  functions: allMarkdownRemark(limit: 2000, sort: {order: ASC, fields: [fileAbsolutePath]}, filter: {frontmatter: {functions: {eq: $tag}}}) @include(if: $incFunction) {
    edges {
      node {
        fields {
          slug
          variationOf
        }
        excerpt(pruneLength: 250)
        frontmatter {
          title
        }
      }
    }
  }
  axisDirections: allMarkdownRemark(limit: 2000, sort: {order: ASC, fields: [fileAbsolutePath]}, filter: {frontmatter: {visualizationTechniques: {elemMatch: {axisDirection: {in: [$tag]}}}}}) @include(if: $incAxis) {
    edges {
      node {
        fields {
          slug
          variationOf
        }
        excerpt(pruneLength: 250)
        frontmatter {
          title
        }
      }
    }
  }
  visualizationTechniques: allMarkdownRemark(limit: 2000, sort: {order: ASC, fields: [fileAbsolutePath]}, filter: {frontmatter: {visualizationTechniques: {elemMatch: {techniqueId: {in: [$tagId]}}}}}) @include(if: $incVisPrinciple) {
    edges {
      node {
        fields {
          slug
          variationOf
        }
        excerpt(pruneLength: 250)
        frontmatter {
          title
        }
      }
    }
  }
  informationTypes: allMarkdownRemark(limit: 2000, sort: {order: ASC, fields: [fileAbsolutePath]}, filter: {frontmatter: {visualizationTechniques: {elemMatch: {informationType: {in: [$tag]}}}}}) @include(if: $incInfo) {
    edges {
      node {
        fields {
          slug
          variationOf
        }
        excerpt(pruneLength: 250)
        frontmatter {
          title
        }
      }
    }
  }
  functionsYaml(name: {eq: $tag}) @include(if: $incFunction) {
    name
    description
  }
  visualizationTechniquesYaml(name: {eq: $tag}) @include(if: $incVisPrinciple) {
    name
    description
  }
  informationTypesYaml(name: {eq: $tag}) @include(if: $incInfo) {
    name
    questionAnswered
    description
  }
  axisDirectionsYaml(name: {eq: $tag}) @include(if: $incAxis) {
    name
    description
  }
}
`;
